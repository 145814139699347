import React from 'react'
import PropTypes from 'prop-types'
import typogr from 'typogr'
import unesc from '../utils/unescape'
import isDev from '../utils/is-dev'

// Ours
import { BlockWrapper } from './MainBlocks'

const HeaderAbstract = props => {
  // <svg
  //   viewBox="0 0 1000 1000"
  //   className="block max-width-5 z0 absolute top-0 left-0"
  //   style={{
  //     display: 'block',
  //     outline: '2px solid blue',
  //   }}>
  //   <circle r="30%" cx="67.5%" cy="10%" fill="red" />
  // </svg>

  let bgClass = props.bg ? `bg-${props.bg}` : ''

  if (
    isDev() &&
    props.subtitle &&
    typeof props.subtitle === 'string' &&
    160 >= props.subtitle.length
  ) {
    console.warn('Subtitle too long: ', props.subtitle)
  }

  return (
    <header
      className={`HeaderAbstract overflow-hidden relative height-75vh ${bgClass} ${
        props.bgImage ? 'bg-cover bg-center bg-black' : ''
      }`}
      style={{
        backgroundImage: props.bgImage ? `url(${props.bgImage})` : undefined,
      }}>
      <div
        className={`col-12 height-100 relative ${
          props.bgImage ? '' : 'bg-darken-3'
        }`}>
        <BlockWrapper bg="transparent" color={props.color} maxWidth={5}>
          <div className="sm-flex justify-end">
            <div className="relative">
              <div className={`HeaderAbstract-shape ${bgClass}`} />
              <div className={`HeaderAbstract-shape2 ${bgClass}`} />
              <div className="HeaderAbstract-wrapper relative z1">
                <h1 className="z1 h4 sm-h3 line-height-2 m0">
                  <div className={`${props.titleColor}`}>{props.title}</div>
                </h1>
                {props.subtitle && 160 >= props.subtitle.length ? (
                  <p
                    className="h4 sm-h3 line-height-2 m0"
                    dangerouslySetInnerHTML={{
                      __html: typogr(unesc(props.subtitle)).typogrify(),
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </BlockWrapper>
      </div>
    </header>
  )
}

HeaderAbstract.defaultProps = {
  color: 'white',
  titleColor: 'black',
  bg: 'magenta',
}

HeaderAbstract.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default HeaderAbstract
