import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import fecha from 'fecha'

// Ours
import getCtaIdFromSelect from '../utils/get-ctaid-from-select'
import SEO from '../components/SEO'
import CallToAction from '../components/CallToAction'
import HeaderAbstract from '../components/HeaderAbstract'
import MainBlocksMap from '../components/MainBlocksMap'
import { Text } from '../components/MainBlocks'

const PostDate = props => {
  if (!props.date || typeof props.date !== 'object') {
    return null
  }

  let date = props.date

  return (
    <a href={props.href} title={props.title}>
      <time title={fecha.format(date)} dateTime={date} className="mb0">
        {fecha.format(date, 'MMMM d, YYYY')}
      </time>
    </a>
  )
}

PostDate.defaultProps = {
  title: '',
  href: '#',
}

PostDate.defaultProps = {
  title: PropTypes.string,
  href: PropTypes.string,
  date: PropTypes.object,
}

const PostTemplate = props => {
  const data = props.data
  const post = data.wpPost
  const blocks = post.main.mainContent

  let chosenCta = getCtaIdFromSelect(post.details)
  let baseUrl = data.wp.acfOptions.acfOptions.ogUrl
  let headerImage =
    post.featuredImageDuotone && post.featuredImageDuotone.node
      ? post.featuredImageDuotone.node.localFile.childImageSharp.resize.src
      : null
  let socialMediaImage =
    post.featuredImage.node && post.featuredImage.node
      ? post.featuredImage.node.localFile.childImageSharp.resize.src
      : null

  let date = new Date(post.date)
  let firstCategory = null

  // Insights are intended to be evergreen, so avoid prominently
  // showing the date like we do with the news posts
  if (
    post.categories &&
    post.categories.nodes &&
    post.categories.nodes.length
  ) {
    firstCategory = post.categories.nodes[0].slug
    if (firstCategory === 'insights') {
      date = null
    }
  }

  // Support for old pages
  let oldContentStructure =
    post.content && post.content !== '' ? <Text text={post.content} /> : null

  return (
    <div className="WPPost bg-white">
      <SEO
        title={post.title}
        description={post.details.description}
        featuredImage={socialMediaImage}
        date={date}
      />
      <article>
        <HeaderAbstract
          bg="navy"
          titleColor="yellow"
          title={post.title}
          subtitle={post.main.subtitle || post.details.description}
          bgImage={headerImage}
        />
        <MainBlocksMap
          blocks={blocks}
          theme={firstCategory === 'insights' ? 'insights' : null}
        />
        {oldContentStructure}
        <div className="mt4">
          {date ? (
            <PostDate
              date={date}
              href={`${baseUrl}/news/${post.slug}`}
              title={post.title}
            />
          ) : null}
        </div>
      </article>
      <CallToAction ctaId={chosenCta} />
    </div>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query WordpressPostById($id: String!) {
    wp {
      generalSettings {
        title
      }
      acfOptions {
        acfOptions {
          ogUrl
        }
      }
    }
    wpPost(id: { eq: $id }) {
      nodeType
      date
      slug
      title
      id
      content
      categories {
        nodes {
          name
          slug
        }
      }
      details {
        description
        ctaSelect
      }
      featuredImage {
        node {
          id
          localFile {
            extension
            childImageSharp {
              id
              resize(width: 1920, height: 1200, cropFocus: CENTER) {
                src
              }
            }
          }
        }
      }
      featuredImageDuotone {
        node {
          id
          localFile {
            extension
            childImageSharp {
              id
              resize(width: 1920, height: 1200, cropFocus: CENTER) {
                src
              }
            }
          }
        }
      }
      main {
        subtitle
        ... on WpPost_Main {
          mainContent {
            ... on WpPost_Main_MainContent_SectionHeading {
              fieldGroupName
              text
            }
            ... on WpPost_Main_MainContent_Text {
              fieldGroupName
              sectionHeading
              text
            }
            ... on WpPost_Main_MainContent_Image {
              fieldGroupName
              sectionHeading
              image {
                uri
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              captionDetails {
                detail
                fieldGroupName
                label
                labelCustom
              }
              captionText
              settings {
                showFullWidth
              }
            }
            ... on WpPost_Main_MainContent_TwoColumn {
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              content {
                subhead
                text
                callToAction {
                  target
                  title
                  url
                }
              }
              settings {
                bg
                color
                sticky
              }
            }
            ... on WpPost_Main_MainContent_ThreeColumn {
              fieldGroupName
              sectionHeading
              columns {
                bigNumber
                image {
                  altText
                  localFile {
                    childImageSharp {
                      id
                      resize(width: 1280, height: 1280, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                }
                subhead
                text
              }
              settings {
                showBigNumbers
                showImages
                # showImagesStylized
              }
            }
            ... on WpPost_Main_MainContent_PartnerLogos {
              fieldGroupName
              sectionHeading
              partnerLogos {
                altText
                id
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            ... on WpPost_Main_MainContent_Testimonials {
              fieldGroupName
              testimonials {
                author
                organization
                quote
              }
            }
            ... on WpPost_Main_MainContent_Project {
              fieldGroupName
              project {
                ... on WpProject {
                  title
                  uri
                  projectDetails {
                    projectStatus
                  }
                  details {
                    description
                  }
                  featuredImage {
                    node {
                      id
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpPost_Main_MainContent_Gallery {
              captionText
              settings {
                showFullWidth
              }
              fieldGroupName
              sectionHeading
              gallery {
                altText
                localFile {
                  extension
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
