import React from 'react'
import PropTypes from 'prop-types'
import Section from './MainSection'

const matchesFullWidthBlock = (name, settings) => {
  let result =
    name &&
    (name === 'Main_MainContent_ThreeColumn' ||
      name === 'Main_MainContent_TwoColumn' ||
      name === 'Main_MainContent_Project' ||
      name === 'Main_MainContent_Gallery' ||
      (name === 'Main_MainContent_Image' && settings.showFullWidth))

  return result
}

const MainBlocksMap = props => {
  if (!props.blocks) {
    return null
  }

  let blockSections = []

  // Convert blocks with section headings in them, into an array of
  // sections blocks in those.
  props.blocks.forEach((block, index) => {
    // Two Column, Three Column, and Image w/ Full Width setting are wider,
    // so they always are considered to be a new section, even if they don’t
    // have a section heading.
    if (!block || !block.fieldGroupName) {
      return
    }

    let fieldGroupName = block.fieldGroupName.split(/_(.+)/)[1]
    let matchesCertainFieldGroups = matchesFullWidthBlock(
      fieldGroupName,
      block.settings
    )

    if (
      blockSections.length === 0 ||
      block.sectionHeading ||
      matchesCertainFieldGroups
    ) {
      let blockObj = {
        sectionHeading: block.sectionHeading,
        sticky:
          block.settings && block.settings.sticky
            ? block.settings.sticky
            : null ||
              ((fieldGroupName === 'Main_MainContent_Text' ||
                fieldGroupName === 'Main_MainContent_Gallery') &&
                index !== 0) ||
              (fieldGroupName === 'Main_MainContent_Image' &&
                !block.settings.showFullWidth),
        blocks: [block],
        firstFieldGroupName: fieldGroupName,
      }

      // TODO refacor above ternary into clearer statements, more like this
      if (
        fieldGroupName === 'Main_MainContent_Gallery' &&
        block.settings.showFullWidth === true
      ) {
        blockObj.sticky = false
      }

      if (
        blockObj.sticky ||
        fieldGroupName === 'Main_MainContent_PartnerLogos' ||
        (fieldGroupName === 'Main_MainContent_ThreeColumn' &&
          !block.settings.showImages)
      ) {
        blockObj.maxWidth = 6
      }

      blockSections.push(blockObj)
    } else {
      let lastSection = blockSections[blockSections.length - 1]
      if (lastSection && lastSection.blocks) {
        lastSection.blocks.push(block)
      }
    }
  })

  // Use the converted data structre
  let content = blockSections.map((section, index) => {
    if (!section) {
      return null
    }

    // TODO Maybe handle sticky all in CSS based on a section namespace
    let keyStr = `Section_${index}`
    return <Section key={keyStr} keyPrefix={keyStr} {...section} />
  })

  return (
    <div
      className={`MainBlocks ${
        props.theme ? `MainBlocks--${props.theme}` : ''
      }`}>
      {props.beforeBlocks}
      {content}
      {props.afterBlocks}
    </div>
  )
}

MainBlocksMap.defaultProps = {
  beforeBlocks: null,
  afterBlocks: null,
}

MainBlocksMap.propTypes = {
  beforeBlocks: PropTypes.node,
  blocks: PropTypes.arrayOf(PropTypes.object),
  afterBlocks: PropTypes.node,
  theme: PropTypes.oneOf(['insights', 'expertise']),
}

export default MainBlocksMap
