import React from 'react'
import PropTypes from 'prop-types'
import { H3 } from './Headings'
import * as mainBlocks from '../components/MainBlocks'
import isDev from '../utils/is-dev'

const Section = props => {
  let className = 'MainBlocks-section relative height-100'

  if (
    props.firstFieldGroupName === 'Main_MainContent_TwoColumn' &&
    props.sticky
  ) {
    className = 'MainBlocks-section sticky top-0 height-100'
  }

  return (
    <section data-block-first={props.firstFieldGroupName} className={className}>
      {props.sectionHeading ? (
        <div
          className={`SectionHeading ${
            props.sticky ? 'SectionHeading--sticky' : ''
          }`}>
          <div
            className={`px2 sm-px3 md-px3 lg-px4 pb1 pt2 sm-pt3 md-pt3 lg-pt3 mx-auto max-width-${
              props.maxWidth || 3
            }`}>
            <H3
              fontWeight={600}
              lineHeight={3}
              is="h2"
              mt={0}
              mb={0}
              color="magenta">
              {props.sectionHeading}
            </H3>
          </div>
        </div>
      ) : null}

      <div className="SectionContent">
        {props.blocks && props.blocks.length
          ? props.blocks.map((block, blockIndex) => {
              let blockKeyStr = `${props.keyPrefix}_Block_${blockIndex}`
              let blockName = block.fieldGroupName.split('_')
              blockName = blockName[blockName.length - 1]

              if (mainBlocks[blockName]) {
                let Comp = mainBlocks[blockName]
                return (
                  <Comp {...block} keyPrefix={blockKeyStr} key={blockKeyStr} />
                )
              } else {
                if (isDev()) {
                  return (
                    <pre key={blockKeyStr}>{JSON.stringify(block, 0, 2)}</pre>
                  )
                } else {
                  return <div key={blockKeyStr} data-missing={blockName} />
                }
              }
            })
          : props.children}
      </div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  blocks: PropTypes.arrayOf(PropTypes.object),
  sectionHeading: PropTypes.string,
  sticky: PropTypes.bool,
  firstFieldGroupName: PropTypes.oneOf([
    'Main_MainContent_ThreeColumn',
    'Main_MainContent_TwoColumn',
    'Main_MainContent_Image',
    'Main_MainContent_Text',
    'Main_MainContent_PartnerLogos',
    'Main_MainContent_Project',
  ]),
}

Section.defaultProps = {
  children: null,
}

export default Section
